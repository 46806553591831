import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DatabaseService } from 'src/app/_services/database.service';

@Component({
  selector: 'app-video-add-model',
  templateUrl: './video-add-model.component.html'
})
export class VideoAddModelComponent implements OnInit {
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public matdialog:MatDialog,public db:DatabaseService) { }
  
  ngOnInit() {
    console.log(this.data);
    if(this.data.id){
      this.getDetail(this.data.encrypt_id)
    }    else{
      this.formsInit({});
    }
  }
  getDetail(id){
    this.db.presentLoader();
    
    this.db.postReq({'content_id':id},'content/detail').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.formsInit(resp['data']['detail']);
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
      this.db.dismissLoader();
    })
    
  }
  form: FormGroup;
  
  formsInit(data:any) {
    this.form = this.db.formBuilder.group({
      description: [data.description ? data.description : null ,[Validators.required]],
      youtube_url: [data.youtube_url ? data.youtube_url : null ,[Validators.required]],
      youtube_id: [data.youtube_id ? data.youtube_id : null ],
      ordering: [data.ordering ? data.ordering : null ],
      type: ['video' ],
      status: [data.status ? data.status : 1 ],
      content_id: [data.encrypt_id ],
    })
  }
  onSubmit(){
    if(this.form.invalid){
      this.form.markAllAsTouched()
      return;
    }
    this.form.controls['youtube_id'].setValue(this.form.value.youtube_url.split('embed/')[1]);
    let reqData = this.form.value
    this.db.presentLoader();
    this.db.postReq(reqData,this.data.id ? 'content/update' : 'content/add').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.db.successAlert(resp['status'],resp['message']);
        this.matdialog.closeAll();
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
      this.db.errDismissLoader();
    })
    
  }
  
  
}
