import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';

@Component({
  selector: 'app-announcement-add',
  templateUrl: './announcement-add.component.html'
})
export class AnnouncementAddComponent implements OnInit {
  tabActiveType:any={};
  
  // salessearch:any={};
  // distributorsearch:any={};
  // directdealersearch:any={};
  // dealersearch:any={};
  
  constructor(private _location: Location) 
  {
    this.tabActive('tab1');
  }
  
  ngOnInit() {
  }
  tabActive(tab:any)
  {
    this.tabActiveType = {};
    this.tabActiveType[tab] = true; 
  }
  
  backClicked() {
    this._location.back();
  }
  
}
