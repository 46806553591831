import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DatabaseService } from 'src/app/_services/database.service';

@Component({
  selector: 'app-sales-user-tab',
  templateUrl: './sales-user-tab.component.html'
})
export class SalesUserTabComponent implements OnInit {
  constructor(public activatedRouted:ActivatedRoute,public db:DatabaseService) { 
    console.log(this.activatedRouted.snapshot.params.id);
  }

  
  ngOnInit() {
    this.getDetail(this.activatedRouted.snapshot.params.id)
  }
  data:any={};
  getDetail(id){
    this.db.presentLoader();
    this.db.postReq({'user_id':id},'master/sales_user/detail').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.data = resp['data'];   
        console.log(this.data);
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
    })
    
  }
}
