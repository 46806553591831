import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { DatabaseService } from 'src/app/_services/database.service';
import { MatSelectModule } from '@angular/material';
import swal from 'sweetalert';
// import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import * as moment from 'moment'
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-order-add',
  templateUrl: './order-add.component.html'
})
export class OrderAddComponent implements OnInit {

  data:any=[];
  dataNotFound=false
  reqSent=false;

  constructor(private _location: Location,public db:DatabaseService,public actRoute:ActivatedRoute) { 
    if(localStorage.getItem('loginType') == 'user'){this.getCust();}
    
    this.getProducts();
    this.getOrderNo();
    this.form.orderItem=[];
    this.form.basicData={
      net_amt:null,
      total_qty:null,
      total_amt:null,
      tax_amt:null,
      discount_amt:null,
      orderItem:null,
      customer_id:null,
      order_no:null,
      order_date:moment(new Date ()).format('YYYY-MM-DD'),
      delivery_date:moment(new Date ()).format('YYYY-MM-DD'),
      payment_date:moment(new Date ()).format('YYYY-MM-DD')
    };
    this.form.product_detail={
      stock_qty:null,
      order_qty:null,
      rate:null,
      discount:null,
      discount_amt:null,
      tax:null,
      tax_amt:null,
      total_amt:null,
      sub_total:null
    };
    if(localStorage.getItem('loginType') == 'dealer'){this.form.basicData.customer_id= JSON.parse(localStorage.getItem('karbitUserData')).id}
    
    if(this.actRoute.snapshot.params.id){
      this.editHandler(this.actRoute.snapshot.params.id)
    }
  }
  
  form:any={};
  ngOnInit() {
    this.data = new Array(7);
  }
  
  backClicked() {
    this._location.back();
  }
  filter:any={};
  custData:any=[]
  filteredCustData:any=[]
  getCust(){
    let reqData = {
      limit : 100,
      start : 0,
      filter : {company_name:this.filter.company_name}
    }
    this.db.presentLoader();
    this.db.postReq(reqData,'order/dr_list').subscribe(resp=>{
      if(resp['status'] == 'success'){
        this.custData = resp['result'].data;
        this.filteredCustData = resp['result'].data;
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }
  productsData:any=[]
  filteredProductsData:any=[]
  getProducts(){
    let reqData = {
      limit : 100000,
      start : 0,
      filter : {product_name:this.filter.product_name}
    }
    this.db.presentLoader();
    this.db.postReq(reqData,'order/product_list').subscribe(async resp=>{
      if(resp['status'] == 'success'){
        await resp['result'].data.map(r=>{
          r.searchName = r.product_name.replace(/ /g,'').toLowerCase();
        })
        this.productsData = resp['result'].data;
        this.filteredProductsData = resp['result'].data;
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }
  getOrderNo(){
    let reqData = {
      
    }
    this.db.presentLoader();
    this.db.postReq(reqData,'order/get_order_no').subscribe(resp=>{
      if(resp['status'] == 'success'){
        this.form.basicData.order_no = resp['result'].data.order_no;
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }
  paymentDateHandler(){    
    let index = this.custData.findIndex(r=>(r.id === this.form.basicData.customer_id))
    if(index!=-1){
      this.form.basicData.payment_date = moment(new Date()).add(this.custData[index].credit_day, 'days').format("YYYY-MM-DD");
    }
  }
  calc(){
    if(this.form.product_detail.product_id && this.form.basicData.customer_id){
      this.db.postReq({product_id:this.form.product_detail.product_id,customer_id:this.form.basicData.customer_id},'order/dr_product_discount').subscribe(resp=>{
        let product_detail = this.productsData.filter(r=>r.id===this.form.product_detail.product_id)[0]
        let dr_disc_data;
        if(resp['result'].data.length){
          if(resp['result'].data[0].min_qty <= this.form.product_detail.order_qty){
            dr_disc_data = resp['result'].data[0];
            this.form.product_detail.discount = dr_disc_data.discount;  
          }else{
            this.form.product_detail.discount = product_detail.discount_percentage;  
          }
        }else{
          this.form.product_detail.discount = product_detail.discount_percentage;  
        }
        
        this.form.product_detail.rate = parseFloat(product_detail.sale_price);
        this.form.product_detail.stock_qty = product_detail.stock_qty;
        this.form.product_detail.product_name = product_detail.product_name;
        this.form.product_detail.product_group = product_detail.product_group;
        this.form.product_detail.product_code = product_detail.product_code;
        this.form.product_detail.customer_id = this.form.customer_id;
        this.form.product_detail.dispatch_qty = 0;
        this.form.product_detail.pending_qty = parseInt(this.form.product_detail.order_qty);
        
        this.form.product_detail.tax = parseFloat(product_detail.tax_percentage);  
        
        this.form.product_detail.net_amt =  (this.form.product_detail.rate * this.form.product_detail.order_qty)
        this.form.product_detail.discount_amt = ((this.form.product_detail.net_amt / 100 ) * this.form.product_detail.discount)  
        this.form.product_detail.sub_total = this.form.product_detail.net_amt -  this.form.product_detail.discount_amt 
        this.form.product_detail.tax_amt =  (((this.form.product_detail.net_amt - this.form.product_detail.discount_amt) / 100) *  this.form.product_detail.tax)
        this.form.product_detail.total_amt = ((this.form.product_detail.net_amt - this.form.product_detail.discount_amt ) + this.form.product_detail.tax_amt)
        
        this.form.product_detail.net_amt = parseFloat(this.form.product_detail.net_amt).toFixed(2)
        this.form.product_detail.sub_total = parseFloat(this.form.product_detail.sub_total).toFixed(2)
        this.form.product_detail.discount_amt = parseFloat(this.form.product_detail.discount_amt).toFixed(2)
        this.form.product_detail.tax_amt = parseFloat(this.form.product_detail.tax_amt).toFixed(2)
        this.form.product_detail.total_amt = parseFloat(this.form.product_detail.total_amt).toFixed(2)
      })
      
    }else{
      this.form.product_detail={
        stock_qty:null,
        order_qty:null,
        rate:null,
        discount:null,
        discount_amt:null,
        tax:null,
        tax_amt:null,
        total_amt:null,
        sub_total:null
      };
    }
  }
  addToList(){
    if(!this.form.product_detail.product_id){
      this.db.presentAlert('Error','Please select product first!');
      return;
    }
    if(!this.form.product_detail.order_qty || this.form.product_detail.order_qty < 0){
      this.db.presentAlert('Error','Please enter a valid quantity!');
      return;
    }
    
    let i = this.form.orderItem.findIndex(r=>r.product_id === this.form.product_detail.product_id)
    if(i != -1){
      this.form.orderItem.splice(i,1)
    }
    
    this.form.orderItem.push(this.form.product_detail);
    
    
    this.form.basicData.net_amt = 0;
    this.form.basicData.total_qty = 0;
    this.form.basicData.total_amt = 0;
    this.form.basicData.tax_amt = 0;
    this.form.basicData.discount_amt = 0;
    this.form.basicData.sub_total = 0;
    this.form.orderItem.map(r=>{
      this.form.basicData.total_qty = parseFloat(this.form.basicData.total_qty) + parseInt(r.order_qty)
      this.form.basicData.net_amt = parseFloat(this.form.basicData.net_amt) + parseFloat(r.net_amt)
      this.form.basicData.total_amt = parseFloat(this.form.basicData.total_amt) + parseFloat(r.total_amt)
      this.form.basicData.tax_amt = parseFloat(this.form.basicData.tax_amt) + parseFloat(r.tax_amt)
      this.form.basicData.discount_amt = parseFloat(this.form.basicData.discount_amt) + parseFloat(r.discount_amt)
      this.form.basicData.sub_total = parseFloat(this.form.basicData.sub_total) + parseFloat(r.sub_total)
      
      this.form.basicData.net_amt = parseFloat(this.form.basicData.net_amt).toFixed(2)
      this.form.basicData.total_amt = parseFloat(this.form.basicData.total_amt).toFixed(2)
      this.form.basicData.tax_amt = parseFloat(this.form.basicData.tax_amt).toFixed(2)
      this.form.basicData.discount_amt = parseFloat(this.form.basicData.discount_amt).toFixed(2)
      this.form.basicData.sub_total = parseFloat(this.form.basicData.sub_total).toFixed(2)
      r.customer_id = this.form.basicData.customer_id;
    });
    this.form.basicData.total_item = this.form.orderItem.length;
    
    this.form.product_detail={
      stock_qty:null,
      order_qty:null,
      rate:null,
      discount:null,
      discount_amt:null,
      tax:null,
      tax_amt:null,
      total_amt:null,
      sub_total:null,
    };
  }
  onSubmit(){
    let reqData = this.form
    if(this.orderEditId){
      console.log(reqData);
      
      reqData.order_id = this.orderEditId;
    }
    this.db.presentLoader();
    this.db.postReq(reqData,this.orderEditId ? "order/update" :'order/add').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.db.successAlert(resp['status'],resp['message']);
        this.backClicked();
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errDismissLoader();
    })
    
  }
  removeItem(i){
    
    swal({
      title: "Are you sure?",
      text: "You want remove this item!" ,
      icon: "warning",
      buttons: ["Cancel", "Confirm"],
      dangerMode: true,
    })
    .then((willDelete) => {        
      if (willDelete) {
        this.form.orderItem.splice(i,1)
        
      } else {
        swal("Your data is safe!");
      }
    });
    
    
  }
  orderEditId:any
  editHandler(orderId){
    this.orderEditId = orderId
    this.db.presentLoader();
    
    this.db.postReq({'order_id':orderId},'order/detail').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        let orderData = resp['result'];   
        console.log(orderData);
        this.form.basicData = orderData.data;
        this.form.orderItem = orderData.orderItem;
        this.form.basicData.order_date = moment(this.form.basicData.order_date).format('YYYY-MM-DD'),
        this.form.basicData.delivery_date = moment(this.form.basicData.delivery_date).format('YYYY-MM-DD'),
        this.form.basicData.payment_date = moment(this.form.basicData.payment_date).format('YYYY-MM-DD')
        
        
        
        // this.form.basicData.total_qty  = parseFloat(this.form.basicData.total_qty );
        // this.form.basicData.net_amt  = parseFloat(this.form.basicData.net_amt );
        // this.form.basicData.total_amt  = parseFloat(this.form.basicData.total_amt );
        // this.form.basicData.tax_amt  = parseFloat(this.form.basicData.tax_amt );
        
        // this.form.orderItem.map(r=>{
        //   r.order_qty = parseInt(r.order_qty)
        //   r.net_amt = parseFloat(r.net_amt)
        //   r.total_amt = parseFloat(r.total_amt)
        //   r.tax_amt = parseFloat(r.tax_amt)
        //   r.discount_amt = parseFloat(r.discount_amt)
        // })
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
    })
    
  }
}