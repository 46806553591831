import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { FormGroup, Validators } from '@angular/forms';
import { DatabaseService } from 'src/app/_services/database.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-role-add',
  templateUrl: './role-add.component.html'
})
export class RoleAddComponent implements OnInit {
  
  constructor(private _location: Location,public db:DatabaseService,public actRoute:ActivatedRoute) { }
  
  ngOnInit() {
    if(this.actRoute.snapshot.params.id){
      this.getDetail(this.actRoute.snapshot.params.id)
    }    else{
      this.formsInit({});
    }
  }
  getDetail(id){
    this.db.presentLoader();
    
    this.db.postReq({'role_id':id},'master/role_detail').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.formsInit(resp['data']['detail']);
        
      }else{
if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
    })
    
  }
  form: FormGroup;
  
  formsInit(data:any) {
    this.form = this.db.formBuilder.group({
      name: [data.name ? data.name : null ,[Validators.required]],
      status: [data.status ? data.status.toString() :"1" , [Validators.required]],
      for_system_user: [data.for_system_user ? data.for_system_user : false , []],
      for_sales_user: [data.for_sales_user ? data.for_sales_user : false , []],
      role_id: [data.id ? data.id : null],
    })
  }
  onSubmit(){
    if(this.form.invalid){
      this.form.controls['name'].markAsTouched();
      this.form.controls['status'].markAsTouched();
      return
    }
    if(!this.form.value.for_sales_user && !this.form.value.for_system_user){
      this.db.presentAlert('Alert','Please select atleast one user type!');
      return;
    }
    console.log(this.form.value);
    this.db.presentLoader();
    this.db.postReq(this.form.value,this.actRoute.snapshot.params.id ? 'master/update_role' : 'master/add_role').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.db.successAlert(resp['status'],resp['message']);
        this.backClicked();
      }else{
if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errDismissLoader();
    })
    
  }
  
  backClicked() {
    this._location.back();
  }
  
}
