import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { MatDialog } from '@angular/material';
// import { ActivityModelComponent } from 'src/app/activity-model/activity-model.component';

@Component({
  selector: 'app-us-lead-list',
  templateUrl: './us-lead-list.component.html'
})
export class UsLeadListComponent implements OnInit {
  data:any=[];
  constructor(private _location: Location, public dialog: MatDialog) { }

  ngOnInit() {
    this.data = new Array(10);
  }
  backClicked() {
    this._location.back();
  }


  viewActivity(): void {
    // const dialogRef = this.dialog.open(ActivityModelComponent, {
    //   width: '600px',
    //   autoFocus: false,
    //   data: {}
    // });

    // dialogRef.afterClosed().subscribe(result => {
    //   console.log('The dialog was closed');
    // });
  }

}
