import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { FormGroup, Validators } from '@angular/forms';
import { DatabaseService } from 'src/app/_services/database.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html'
})
export class NewsAddComponent implements OnInit {
  
  constructor(private _location: Location,public db:DatabaseService,public actRoute:ActivatedRoute) { }
  
  ngOnInit() {
    this.db.getStates()
    if(this.actRoute.snapshot.params.id){
      this.getDetail(this.actRoute.snapshot.params.id)
    }    else{
      this.formsInit({});
    }
  }
  getDetail(id){
    this.db.presentLoader();
    
    this.db.postReq({'content_id':id},'content/detail').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.formsInit(resp['data']['detail']);
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
      this.db.dismissLoader();
    })
    
  }
  form: FormGroup;
  
  formsInit(data:any) {
    this.form = this.db.formBuilder.group({
      title: [data.title ? data.title : null ,[Validators.required]],
      description: [data.description ? data.description : null ,[Validators.required]],
      ordering: [data.ordering ? data.ordering : null ,[Validators.required]],
      status: [data.status ? data.status.toString() :"1" , [Validators.required]],
      content_id: [data.encrypt_id ? data.encrypt_id : null],
      url: [data.url ? data.url : null ,[Validators.required]],
      thumbnail_image: [data.thumbnail_image ? data.thumbnail_image : null ],
      type: ['news' ],
      image:[]
    })
  }
  onSubmit(){
    if(this.form.invalid){
      this.form.markAllAsTouched();
      return
    }
    console.log(this.form.value);
    let reqData= this.form.value
    reqData.type='news';
    this.db.presentLoader();
    this.db.postReq(reqData,this.actRoute.snapshot.params.id ? 'content/update' : 'content/add').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.db.successAlert(resp['status'],resp['message']);
        this.backClicked();
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
      this.db.errDismissLoader();
    })
    
  }
  changeListener($event): void {
    console.log($event);
    
    var file = $event.target.files[0];
    var reader = new FileReader();
    var image
    reader.onloadend = function () {
      image = reader.result;
    }
    setTimeout(() => {
      console.log(image);
      let reqData = {base64:image}
      this.db.presentLoader();
      this.db.postReq(reqData,'base64_to_file').subscribe(resp=>{
        this.db.dismissLoader();
        if(resp['status'] == 'success'){
         this.form.controls['thumbnail_image'].setValue(resp['response'].fileName);
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        this.db.errDismissLoader();
      })
    }, 100);
    
    reader.readAsDataURL(file);
    
  }
  backClicked() {
    this._location.back();
  }
  
}
