import { Component, OnInit, Renderer2 } from '@angular/core';
import {Location} from '@angular/common';
import { MatDialog } from '@angular/material';
import * as moment from 'moment';
import { DatabaseService } from 'src/app/_services/database.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-us-checkins-list',
  templateUrl: './us-checkins-list.component.html'
})
export class UsCheckinsListComponent implements OnInit {
 
  data:any=[];
  dataNotFound=false
  reqSent=false
  autoFocus?: boolean = true;
  
  constructor(
    private _location: Location,
    public dialog: MatDialog,
    public db:DatabaseService,
    public activatedRouted:ActivatedRoute
    ) { }
    ngOnInit() {
      this.filter.status = ''
      this.todayDate =  moment(new Date ()).format('YYYY-MM-DD')
      this.getData();
    }
    todayDate :any
    pageNo:any=1;
    tempPageNo =1;;
    totalPages:any
    start:any=0;
    limit:any=50;
    totalLength:any;
    filter:any={};
    getData(){
      this.dataNotFound = false;
      this.reqSent = false;
      let reqData = {
        limit : this.limit,
        start : this.start,
        filter : this.filter
      }
      reqData.filter.encrypt_user_id  = this.activatedRouted.snapshot.params.id
      this.db.presentLoader();
      this.db.postReq(reqData,'checkin/list').subscribe(resp=>{
        this.reqSent = true;
        if(resp['status'] == 'success'){
          this.data = resp['result'].data;
          if(!this.data.length) this.dataNotFound = true;
          this.totalLength = resp['result'].totalCount;
          this.totalPages = Math.ceil(this.totalLength / this.limit);
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        this.db.errHandler(err);
      })
    }
    pagination(action){
      
      if(action == 'pageNo'){
        if(this.pageNo > 0 && this.pageNo <=this.totalPages){
          this.start = this.limit*(this.pageNo-1)
        }else{
          this.pageNo = Math.ceil(this.totalLength / this.data.length);
        }
      }
      else if(action=='next'){
        
               if(this.totalLength == (this.start+this.data.length)) return;

        this.start = this.start+this.limit
        this.pageNo ++ ;
      }else{
        if(this.pageNo == 1) return
        this.start = this.start-this.limit
        this.pageNo -- ;
      }
      this.getData();
      this.tempPageNo = this.pageNo
    }
    refresh(){
      this.start = 0;
      this.limit = 50;
      this.filter = {};
      this.getData();
    }
    
    backClicked() {
      this._location.back();
    }  
   
    
    downloadExcel(){
      let reqData = {
        filter : this.filter
      }
      reqData.filter.encrypt_user_id  = this.activatedRouted.snapshot.params.id
      this.db.presentLoader();
      this.db.postReq(reqData,'checkin/list/excel').subscribe(resp=>{
        this.reqSent = true;
        if(resp['status'] == 'success'){
          this.db.exportAsExcelFile(resp['result'].data,resp['result'].file_name ? resp['result'].file_name : 'exported_data');
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        this.db.errHandler(err);
      })
    }
  }
  