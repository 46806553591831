import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';

@Component({
  selector: 'app-us-travel-list',
  templateUrl: './us-travel-list.component.html'
})
export class UsTravelListComponent implements OnInit {

  data:any=[];
  constructor(private _location: Location) { }

  ngOnInit() {
    this.data = [
      {
        "date":"28 May 2020",
        "mmyy":"July 2020",
        "statusclr":"green-clr",
        "status":"Approved",
      },
      {
        "date":"27 May 2020",
        "mmyy":"August 2020",
        "statusclr":"yellow-clr",
        "status":"Pending",
      },
      {
        "date":"26 May 2020",
        "mmyy":"September 2020",
        "statusclr":"red-clr",
        "status":"Reject",
      },
      {
        "date":"26 May 2020",
        "mmyy":"August 2020",
        "statusclr":"green-clr",
        "status":"Approved",
      },
      {
        "date":"25 May 2020",
        "mmyy":"July 2020",
        "statusclr":"yellow-clr",
        "status":"Pending",
      },
      {
        "date":"24 May 2020",
        "mmyy":"June 2020",
        "statusclr":"green-clr",
        "status":"Approved",
      },
      {
        "date":"23 May 2020",
        "mmyy":"July 2020",
        "statusclr":"red-clr",
        "status":"Reect",
      },
    ];
  }
  backClicked() {
    this._location.back();
  }
}
