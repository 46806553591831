import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import * as moment from 'moment';
import { DatabaseService } from 'src/app/_services/database.service';
import swal from 'sweetalert';
import {Location} from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { FollowupModelComponent } from 'src/app/followup-model/followup-model.component';

@Component({
  selector: 'app-dealer-followup-list',
  templateUrl: './dealer-followup-list.component.html'
})
export class DealerFollowupListComponent implements OnInit {
 
  data:any=[];
  dataNotFound=false
  reqSent=false
  autoFocus?: boolean = true;
  
  constructor(
    public activatedroute:ActivatedRoute,
    private _location: Location,
    public dialog: MatDialog,
    public db:DatabaseService
    ) { 
      this.filter.gift_status='';
      this.filter.redeem_status = '';
    }
    ngOnInit() {
      this.todayDate =  moment(new Date ()).format('YYYY-MM-DD')
      this.getData();
    }
    todayDate :any
    pageNo:any=1;
    tempPageNo =1;
    totalPages:any
    start:any=0;
    limit:any=50;
    totalLength:any;
    filter:any={};
    getData(){
      this.dataNotFound = false;
      this.reqSent = false;
      let reqData = {
        limit : this.limit,
        start : this.start,
        filter : this.filter,
        customer_id: this.activatedroute.snapshot.params.id,
        registered: 0,
 
      }
      this.db.presentLoader();
      this.db.postReq(reqData,'dis_network/followup_list').subscribe(resp=>{
        this.reqSent = true;
        if(resp['status'] == 'success'){
          this.data = resp['result'].data;
          if(!this.data.length) this.dataNotFound = true;
          this.totalLength = resp['result'].totalCount;
          this.totalPages = Math.ceil(this.totalLength / this.limit);
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        
      })
    }
    pagination(action){
      if(action == 'pageNo'){
        if(this.pageNo > 0 && this.pageNo <=this.totalPages){
          this.start = this.limit*(this.pageNo-1)
        }else{
          this.pageNo = Math.ceil(this.totalLength / this.data.length);
        }
      }
      else if(action=='next'){
               if(this.totalLength == (this.start+this.data.length)) return;

        this.start = this.start+this.limit
        this.pageNo ++ ;
      }else{
        if(this.pageNo == 1) return
        this.start = this.start-this.limit
        this.pageNo -- ;
      }
      this.getData();
      this.tempPageNo = this.pageNo
    }
    refresh(){
      this.start = 0;
      this.limit = 50;
      this.filter = {};
      this.getData();
    }
   
    backClicked() {
      this._location.back();
    }
    // openDetail(row): void {
    //   console.log(row);
    
    //   const dialogRef = this.dialog.open(RoleDetailModelComponent, {
    //     width: '768px',
    //     autoFocus: false,
    //     data: row
    //   });
    
    //   dialogRef.afterClosed().subscribe(result => {
    //     console.log('The dialog was closed');
    //   });
    // }
  
    downloadExcel(){
      this.dataNotFound = false;
      this.reqSent = false;
      let reqData = {
        filter : this.filter,
        customer_id: this.activatedroute.snapshot.params.id,
        registered: 0,
 
      }
      this.db.presentLoader();
      this.db.postReq(reqData,'dis_network/followup_list/excel').subscribe(resp=>{
        this.reqSent = true;
        if(resp['status'] == 'success'){
          this.db.exportAsExcelFile(resp['result'].data,resp['result'].file_name ? resp['result'].file_name : 'exported_data');
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        
      })
    }
    openFollowup(data:any): void {
      const dialogRef = this.dialog.open(FollowupModelComponent, {
        width: '360px',
        autoFocus: false,
        data: data
      });
  
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        this.getData();
      });
    }
    delete(data){
      swal({
        title: "Are you   sure?",
        text:  "You want to Delete this!",
        icon: "warning",
        buttons: ["Cancel", "Confirm"],
        dangerMode: true,
      })
      .then((willDelete) => {        
        if (willDelete) {
          this.db.presentLoader();
          data.followup_id = data.id;
          this.db.postReq(data,'followup/delete').subscribe(resp=>{
            if(resp['status'] == 'success'){
              swal('Deleted', {
                icon: "success",
              });
              this.getData();
            }else{
              if(resp['message']=='Invalid token'){
                this.db.sessionExpire();
                return;
              }
              this.db.presentAlert(resp['status'],resp['message'])
            }
          })
          
        } else {
          swal("Your data is safe!");
        }
      });
    }
  }
  