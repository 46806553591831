import { Component, Inject, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, Validators } from '@angular/forms';
import { DatabaseService } from 'src/app/_services/database.service';

@Component({
  selector: 'app-payment-status-model',
  templateUrl: './add-bonus.component.html'
})
export class AddBonusModelComponent implements OnInit {

  constructor(public db:DatabaseService,@Inject(MAT_DIALOG_DATA) public data: any,private _location: Location) { }

  ngOnInit() {
    this.formsInit(this.data);
  }

  form: FormGroup;
  formsInit(data:any) {
    this.form = this.db.formBuilder.group({
      points: [null ,[Validators.required]],
      remark: [null ,[Validators.required]],
      customer_id: [data.id ? data.id : null ,[Validators.required]],
    })
    console.log(this.form.value);

  }
  backClicked() {
    this._location.back();
  }

  onSubmit(){
    console.log(this.form);
    
    if(this.form.invalid){
      this.form.markAllAsTouched()
      return;
    }
    let reqData = this.form.value
    reqData.point = reqData.points
    this.db.presentLoader();
    this.db.postReq(reqData,'dis_network/add_bonus').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.db.successAlert(resp['status'],resp['message']);
        this.db.matdialog.closeAll();
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
      this.db.errDismissLoader();
    })
    
  }
  
}
