import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DatabaseService } from 'src/app/_services/database.service';
import {Location} from '@angular/common';
import { ContactEditModelComponent } from '../contact-edit-model/contact-edit-model.component';
import { MatDialog } from '@angular/material';
import { DirtributionInfoEditModelComponent } from '../dirtribution-info-edit-model/dirtribution-info-edit-model.component';
import  swal from 'sweetalert';

@Component({
  selector: 'app-dr-detail',
  templateUrl: './dr-detail.component.html',
  // styleUrls: ['./dr-detail.component.scss']
})
export class DrDetailComponent implements OnInit {
  
  constructor(public dialog : MatDialog, public activatedroute:ActivatedRoute ,private _location: Location,public db:DatabaseService) { }
  
  ngOnInit() {
    this.getDetail(this.activatedroute.snapshot.params.id)
  }
  editSalesUser:any=false
  usersAssigned:any=[];
  usersData:any=[]
  filteredUsersData:any=[]
  getUsers(){
    this.usersAssigned=[];
    let reqData = {
      limit : 30000,
      start : 0,
      filter : {}
    }
    this.db.presentLoader();
    this.db.postReq(reqData,'dis_network/sales_user').subscribe(resp=>{
      if(resp['status'] == 'success'){
        resp['result'].data.map(r=>{
          r.user_name = r.name
          r.user_id = r.id
        })
        this.usersData = resp['result'].data;
        this.filteredUsersData = this.usersData
        this.usersData.map(r=>{
          let i = this.data.salesUserDetail.findIndex(row=>row.user_id == r.id)
          if(i!=-1)this.usersAssigned.push(r)
        })
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }
  saveUsers(){
    this.db.presentLoader();
    this.db.postReq({customer_id:this.data.basicDetail.id,userIds:this.usersAssigned},'dis_network/assign_sales_user').subscribe(resp=>{
      if(resp['status'] == 'success'){
        this.editSalesUser=false
       this.getDetail(this.activatedroute.snapshot.params.id);
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }
  data:any={};
  getDetail(id){
    this.db.presentLoader();
    
    this.db.postReq({'customer_id':id},'dis_network/detail').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.data = resp['data'];   
        console.log(this.data);
        // this.getUsers();
        
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
    })
    
  }
  backClicked() {
    this._location.back();
  }
  
  openDistribution(): void {
    const dialogRef = this.dialog.open(DirtributionInfoEditModelComponent, {
      width: '600px',
      autoFocus: false,
      data: this.data.basicDetail
    });
    
    dialogRef.afterClosed().subscribe(result => {
      this.getDetail(this.activatedroute.snapshot.params.id)    });
      
    }
    
    openEditContact(i): void {
      const dialogRef = this.dialog.open(ContactEditModelComponent, {
        width: '700px',
        autoFocus: false,
        data: this.data.contactDetail[i]
      });
      
      dialogRef.afterClosed().subscribe(result => {
        this.getDetail(this.activatedroute.snapshot.params.id)    });
      }
      addContact(): void {
        const dialogRef = this.dialog.open(ContactEditModelComponent, {
          width: '700px',
          autoFocus: false,
          data: {customer_id:this.activatedroute.snapshot.params.id}
        });
        
        dialogRef.afterClosed().subscribe(result => {
          this.getDetail(this.activatedroute.snapshot.params.id)      });
        }
        contactDelete(data){
          swal({
            title: "Are you sure?",
            text: "You want delete this contact detail!" ,
            icon: "warning",
            buttons: ["Cancel", "Confirm"],
            dangerMode: true,
          })
          .then((willDelete) => {        
            if (willDelete) {
              this.db.presentLoader();
              this.db.postReq({customer_id:data.customer_id,contact_id:data.id},'dis_network/delete_contact_detail').subscribe(resp=>{
                if(resp['status'] == 'success'){
                  swal("Deleted!", {
                    icon: "success",
                  });
                  this.getDetail(this.activatedroute.snapshot.params.id)
                  
                }else{
                  if(resp['message']=='Invalid token'){
                    this.db.sessionExpire();
                    return;
                  }
                  this.db.presentAlert(resp['status'],resp['message'])
                }
              })
              
            } else {
              swal("Your data is safe!");
            }
          });
        }
      }
      