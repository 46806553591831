import { Component, OnInit, Renderer2 } from '@angular/core';
import {Location} from '@angular/common';
import { MatDialog } from '@angular/material';
import * as moment from 'moment';
import { LeaveStatusModelComponent } from 'src/app/leave-status/status-model.component';
import { DatabaseService } from 'src/app/_services/database.service';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-us-leave-list',
  templateUrl: './us-leave-list.component.html'
})
export class UsLeaveListComponent implements OnInit {
 
  data:any=[];
  dataNotFound=false
  reqSent=false
  
  
  typeName:any 
  constructor(
    private _location: Location,
    public dialog: MatDialog,
    public db:DatabaseService,
    private render: Renderer2,
    public activatedRouted:ActivatedRoute
    ) {  
      
    }
    ngOnInit() {
      this.filter.status = this.tabActive;
      this.getCounters();
      this.todayDate =  moment(new Date ()).format('YYYY-MM-DD')
    }
    tabActiveType:any={};
    tabActive:any='All'
    todayDate :any
    pageNo:any = 1;
    tempPageNo = 1;
    totalPages:any
    start:any = 0;
    limit:any = 50;
    totalLength:any;
    filter:any={};
    getData(){
      this.dataNotFound = false;
      this.reqSent = false;
      // this.filter.search = null
      this.filter.status =  this.tabActive;
      let reqData = {
        limit : this.limit,
        start : this.start,
        filter : this.filter
      }
      reqData.filter.encrypt_user_id  = this.activatedRouted.snapshot.params.id

      this.db.presentLoader();
      this.db.postReq(reqData,'leave/list').subscribe(resp=>{
        this.reqSent = true;
        if(resp['status'] == 'success'){
          this.data = resp['result'].data;
          if(!this.data.length) this.dataNotFound = true;
          this.totalLength = resp['result'].totalCount;
          this.totalPages = Math.ceil(this.totalLength / this.limit);
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        
      })
    }
    counters:any=[];
    getCounters(){
      
      // this.db.presentLoader();
      // this.db.postReq({},'leave/list/count').subscribe(resp=>{
      //   this.reqSent = true;
      //   if(resp['status'] == 'success'){
      //     this.counters= resp['result'].data
      //     console.log(this.counters);
          this.getData();
          
      //   }else{
      //     if(resp['message']=='Invalid token'){
      //       this.db.sessionExpire();
      //       return;
      //     }
      //     this.db.presentAlert(resp['status'],resp['message'])
      //   }
      // },err=>{
      //   this.db.errHandler(err);
      // })
    }
    pagination(action){
      
      if(action == 'pageNo'){
        if(this.pageNo > 0 && this.pageNo <=this.totalPages){
          this.start = this.limit*(this.pageNo-1)
        }else{
          this.pageNo = Math.ceil(this.totalLength / this.data.length);
        }
      }
      else if(action=='next'){
        
               if(this.totalLength == (this.start+this.data.length)) return;

        this.start = this.start+this.limit
        this.pageNo ++ ;
      }else{
        if(this.pageNo == 1) return
        this.start = this.start-this.limit
        this.pageNo -- ;
      }
      this.getData();
      this.tempPageNo = this.pageNo
    }
    refresh(){
      this.start = 0;
      this.limit = 50;
      this.filter = {};
      this.getData();
    }
   
    backClicked() {
      this._location.back();
    } 
    status:boolean = false
    
    toggleNav() 
    {
      this.status = !this.status;
      if(this.status) {
        this.render.addClass(document.body, 'toggle-active-tlp');
      }
      else {
        //this.render.removeClass(document.body, 'toggle-active-tlp');
      }
    }
    
   
  
    downloadExcel(){
      this.filter.gift_status = this.tabActive == 'All' ? '' : this.tabActive;
      let reqData = {
        filter : this.filter
      }
      reqData.filter.encrypt_user_id  = this.activatedRouted.snapshot.params.id

      this.db.presentLoader();
      this.db.postReq(reqData,'leave/list/excel').subscribe(resp=>{
        this.reqSent = true;
        if(resp['status'] == 'success'){
          this.db.exportAsExcelFile(resp['result'].data,resp['result'].file_name ? resp['result'].file_name : 'exported_data')
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        
      })
    }
  }
  
  
  
  