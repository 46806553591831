import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import {MatDialog} from '@angular/material/dialog';
import { PaymentStatusModelComponent } from '../payment-status-model/payment-status-model.component';
import { PaymentAddModelComponent } from '../payment-add-model/payment-add-model.component';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html'
})
export class PaymentComponent implements OnInit {

  data:any=[];
  autoFocus?: boolean = true;

  constructor(private _location: Location, public dialog: MatDialog) { }

  ngOnInit() {
    this.data = [
      {
        "date":"28 May 2020",
        "createby":"Satender Bhati",
        "value":"5,00,000",
        "cus":"Amit Saini",
        "mobile":"9989891234",
        "ptype":"NEFT",
        "tran":"| TXN : 9854785412781458",
        "statusclr":"green-clr",
        "status":"Received",
        "firm":"Lanco Infratech",
        "type":"Distributor",
      },
      {
        "date":"27 May 2020",
        "createby":"Shivam Kumar",
        "value": "3,50,000",
        "cus":"Deepak Gupta",
        "mobile":"8010025732",
        "ptype":"Cash",
        "statusclr":"yellow-clr",
        "status":"Pending",
        "firm":"Nilkamal Plastics",
        "type":"Distributor",
      },
      {
        "date":"26 May 2020",
        "createby":"Kapil Raghav",
        "value": "2,10,000",
        "cus":"Shyam Yadav",
        "mobile":"9078456712",
        "ptype":"NEFT",
        "tran":"| TXN : 985478541278789",
        "statusclr":"green-clr",
        "status":"Received",
        "firm":"Sobha Ltd.",
        "type":"Direct Dealer",
      },
      {
        "date":"26 May 2020",
        "createby":"Sanjay Singh",
        "value": "1,80,000",
        "cus":"Naresh Gupta",
        "mobile":"7867561219",
        "ptype":"NEFT",
        "tran":"| TXN : 9854785412785512",
        "statusclr":"green-clr",
        "status":"Received",
        "firm":"The Himalaya Drug Company",
        "type":"Distributor",
      },
      {
        "date":"25 May 2020",
        "createby":"Anil Verma",
        "value": "6,25,000",
        "cus":"Harish Kumar",
        "mobile":"9570671278",
        "ptype":"Cash",
        "statusclr":"yellow-clr",
        "status":"Pending",
        "firm":"Exide Industries",
        "type":"Direct Dealer",
      },
      {
        "date":"24 May 2020",
        "createby":"Jatin Sharma",
        "value":"2,50,000",
        "cus":"Naresh",
        "mobile":"9054126712",
        "ptype":"NEFT",
        "tran":"| TXN : 9854785412781024",
        "statusclr":"green-clr",
        "status":"Received",
        "firm":"Gupta's sons pvt. ltd.",
        "type":"Distributor",
      },
      {
        "date":"23 May 2020",
        "createby":"Anil Saini",
        "value": "3,50,000",
        "cus":"Gautam Kumar",
        "mobile":"9878459065",
        "ptype":"Cash",
        "statusclr":"yellow-clr",
        "status":"Pending",
        "firm":"Mangla's sons pvt. ltd.",
        "type":"Distributor",
      },
      {
        "date":"23 May 2020",
        "createby":"Ramesh Sharma",
        "value": "2,10,000",
        "cus":"A.P. Mehra",
        "mobile":"9313459067",
        "ptype":"Cash",
        "statusclr":"yellow-clr",
        "status":"Pending",
        "firm":"Ballarpur Industries Limited",
        "type":"Direct Dealer",
      },
      {
        "date":"22 May 2020",
        "createby":"Anoop Singla",
        "value": "1,80,000",
        "cus":"Parshant Singh",
        "mobile":"9213674560",
        "ptype":"NEFT",
        "tran":"| TXN : 9854785412781070",
        "statusclr":"green-clr",
        "status":"Received",
        "firm":"Lakshmi Machine Works",
        "type":"Direct Dealer",
      },
      {
        "date":"21 May 2020",
        "createby":"Vinay Gupta",
        "value": "1,55,000",
        "cus":"Anil Jain",
        "mobile":"9967891256",
        "ptype":"Cash",
        "statusclr":"yellow-clr",
        "status":"Pending",
        "firm":"POSCO Indias",
        "type":"Distributor",
      },
    ];
  }

  backClicked() {
    this._location.back();
  }

  openStatusDialog(): void {
    const dialogRef = this.dialog.open(PaymentStatusModelComponent, {
      width: '400px',
      autoFocus: false,
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  openAdd(): void {
    const dialogRef = this.dialog.open(PaymentAddModelComponent, {
      width: '400px',
      autoFocus: false,
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

}
