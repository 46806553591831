import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-vendor-page-menu',
  templateUrl: './vendor-page-menu.component.html'
})
export class VendorPageMenuComponent implements OnInit {
  
  constructor(private router : Router,    public activatedRoutes:ActivatedRoute
    ) {
      console.log('routername', this.router.url);
    }
    
    ngOnInit() {
    }
    
  }
  