import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { DatabaseService } from 'src/app/_services/database.service';

@Component({
  selector: 'app-redeem-detail',
  templateUrl: './redeem-detail.component.html'
})
export class RedeemDetailComponent implements OnInit {

  constructor(public activatedroute:ActivatedRoute ,private _location: Location,public db:DatabaseService) { }
  
  ngOnInit() {
    this.getDetail(this.activatedroute.snapshot.params.id)
  }
  data:any={};
  getDetail(id){
    this.db.presentLoader();
    
    this.db.postReq({'redeem_request_id':id},'redeem_request/detail').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.data = resp['result'].data;   
        console.log(this.data);
        if(this.data.cust_type_id == 3){
          this.filter.used_by_carpenter = this.data.customer_id
        }else if(this.data.cust_type_id == 4){
          this.filter.used_by_architect = this.data.customer_id
        }
        this.filter.offer_id = this.data.offer_id

        this.getData();
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
    })
    
  }
  backClicked() {
    this._location.back();
  }
  coupondata:any=[];
  pageNo:any = 1;
  tempPageNo = 1;
  totalPages:any
  start:any = 0;
  limit:any = 50;
  totalLength:any;
  filter:any={};
  getData(){
   
    let reqData = {
      limit : this.limit,
      start : this.start,
      filter : this.filter
    }
    this.db.presentLoader();
    this.db.postReq(reqData,'coupon/list').subscribe(resp=>{
      if(resp['status'] == 'success'){
        this.coupondata = resp['result'].data;
        this.totalLength = resp['result'].totalCount;
        this.totalPages = Math.ceil(this.totalLength / this.limit);
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }
  pagination(action){
      
    if(action == 'pageNo'){
      if(this.pageNo > 0 && this.pageNo <=this.totalPages){
        this.start = this.limit*(this.pageNo-1)
      }else{
        this.pageNo = Math.ceil(this.totalLength / this.coupondata.length);
      }
    }
    else if(action=='next'){
      
      if(this.totalLength == this.coupondata.length) return;
      this.start = this.start+this.limit
      this.pageNo ++ ;
    }else{
      if(this.pageNo == 1) return
      this.start = this.start+this.limit
      this.pageNo -- ;
    }
    this.getData();
    this.tempPageNo = this.pageNo
  }
  downloadExcel(){
    
    let reqData = {
      filter : this.filter
    }

    reqData.filter.redeem_request_id = this.data.id;
    
    if(this.data.cust_type_id == 3){
      reqData.filter.statusName = "Scanned By Carpenter"
    }else if(this.data.cust_type_id == 4){
      reqData.filter.statusName = "Scanned By Architect"
    }

    this.db.presentLoader();
    this.db.postReq(reqData,'coupon_redeem/list/excel').subscribe(resp=>{
      if(resp['status'] == 'success'){
       this.db.exportAsExcelFile(resp['result'].data,resp['result'].file_name ? resp['result'].file_name : 'exported_data');
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
    
  }
}
