import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';

@Component({
  selector: 'app-announcement-list',
  templateUrl: './announcement-list.component.html'
})
export class AnnouncementListComponent implements OnInit {
  data:any=[];
  status: boolean = false;
  invoice:any=[];
  constructor(private _location: Location) { }
  
  ngOnInit() {
    this.data = new Array(10);
    this.invoice = [
      {
        "date":"28 May 2020",
        "subject": "Lorem Ipsum is simply dummy text of the printing and typesettin",
        "total":"15",
        "read":"10",
        "unread":"5",
        "type": "Dealer",
      },
      {
        "date":"27 May 2020",
        "subject": "It is a long established fact that a reader will be distracted by the",
        "total":"50",
        "read":"20",
        "unread":"30",
        "type": "Direct Dealer",
      },
      {
        "date":"26 May 2020",
        "subject": "There are many variations of passages of Lorem Ipsum available",
        "total":"40",
        "read":"20",
        "unread":"20",
        "type": "Direct Dealer",
      },
      {
        "date":"26 May 2020",
        "subject": "The standard chunk of Lorem Ipsum used since the 1500s",
        "total":"45",
        "read":"22",
        "unread":"23",
        "type": "Dealer",
        
      },
      {
        "date":"25 May 2020",
        "subject": "Sed ut perspiciatis unde omnis iste natus error sit voluptatem",
        "total":"40",
        "read":"18",
        "unread":"22",
        "type": "Direct Dealer",
      },
      {
        "date":"24 May 2020",
        "subject": "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
        "total":"50",
        "read":"22",
        "unread":"28",
        "type": "Direct Dealer",
      },
      {
        "date":"23 May 2020",
        "subject": "Duis aute irure dolor in reprehenderit in voluptate velit",
        "total":"50",
        "read":"25",
        "unread":"25",
        "type": "Dealer",
      },
      {
        "date":"23 May 2020",
        "subject": "Lorem Ipsum is simply dummy text of the printing and typesetting",
        "total":"40",
        "read":"15",
        "unread":"25",
        "type": "Direct Dealer",
      },
      {
        "date":"22 May 2020",
        "subject": "There are many variations of passages of Lorem Ipsum available",
        "total":"45",
        "read":"23",
        "unread":"22",
        "type": "Dealer",
        
      },
      {
        "date":"21 May 2020",
        "subject": "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium ",
        "total":"30",
        "read":"18",
        "unread":"12",
        "type": "Dealer",
      },
    ];
  }
  
  backClicked() {
    this._location.back();
  }
  
  clickEvent()
  {
    this.status = !this.status;       
  }


}
