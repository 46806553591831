import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import {Location} from '@angular/common';
import * as moment from 'moment';
import { DatabaseService } from 'src/app/_services/database.service';
import swal from 'sweetalert';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-project-list',
  templateUrl: './list.component.html'
})
export class ProjectListComponent implements OnInit {
  
  data:any=[];
  dataNotFound=false
  reqSent=false
  autoFocus?: boolean = true;
  
  constructor(
    private _location: Location,
    public dialog: MatDialog,
    public db:DatabaseService,
    public domsanitizer:DomSanitizer
    ) { }
    ngOnInit() {
      this.todayDate =  moment(new Date ()).format('YYYY-MM-DD')
      this.getData();
    }
    todayDate :any
    pageNo:any=1;
    tempPageNo =1;;
    totalPages:any
    start:any=0;
    limit:any=50;
    totalLength:any;
    filter:any={};
    getData(){
      this.dataNotFound = false;
      this.reqSent = false;
      let reqData = {
        limit : this.limit,
        start : this.start,
        filter : this.filter
      }
      this.db.presentLoader();
      this.db.postReq(reqData,'project/list').subscribe(resp=>{
        this.reqSent = true;
        if(resp['status'] == 'success'){
          this.data = resp['result'].data;
          if(!this.data.length) this.dataNotFound = true;
          this.totalLength = resp['result'].totalCount;
          this.totalPages = Math.ceil(this.totalLength / this.limit);
          this.data.map(r=>{
            r.youtube_url_safe = this.domsanitizer.bypassSecurityTrustResourceUrl(r.youtube_url);
          })
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        this.db.errHandler(err);
        
      })
    }
    
    // statusChange(data){
    //   swal({
    //     title: "Are you sure?",
    //     text: data.status == 1  ? "You want to make this Inactive!" : "You want to make this Active!",
    //     icon: "warning",
    //     buttons: ["Cancel", "Confirm"],
    //     dangerMode: true,
    //   })
    //   .then((willDelete) => {        
    //     if (willDelete) {
    //       this.db.presentLoader();
    //       data.status = data.status == 0 ? 1 : 0;
    //       data.content_id = data.encrypt_id;
    //       this.db.postReq(data,'content/update').subscribe(resp=>{
    //         if(resp['status'] == 'success'){
    //           swal(data.status == 0 ? "Inactive!" : "Active", {
    //             icon: "success",
    //           });
    //           this.getData();
    //         }else{
    //           if(resp['message']=='Invalid token'){
    //             this.db.sessionExpire();
    //             return;
    //           }
    //           this.db.presentAlert(resp['status'],resp['message'])
    //         }
    //       })
          
    //     } else {
    //       swal("Your data is safe!");
    //     }
    //   });
    // }
    // delete(data){
    //   swal({
    //     title: "Are you sure?",
    //     text: "Are you sure you want to delete!",
    //     icon: "warning",
    //     buttons: ["Cancel", "Confirm"],
    //     dangerMode: true,
    //   })
    //   .then((willDelete) => {        
    //     if (willDelete) {
    //       this.db.presentLoader();
    //       data.deleted = 1;
    //       data.content_id = data.encrypt_id;
    //       this.db.postReq(data,'content/update').subscribe(resp=>{
    //         if(resp['status'] == 'success'){
    //           swal("Deleted", {
    //             icon: "success",
    //           });
    //           this.getData();
    //         }else{
    //           if(resp['message']=='Invalid token'){
    //             this.db.sessionExpire();
    //             return;
    //           }
    //           this.db.presentAlert(resp['status'],resp['message'])
    //         }
    //       })
          
    //     } else {
    //       swal("Your data is safe!");
    //     }
    //   });
    // }
    pagination(action){
      
      if(action == 'pageNo'){
        if(this.pageNo > 0 && this.pageNo <=this.totalPages){
          this.start = this.limit*(this.pageNo-1)
        }else{
          this.pageNo = Math.ceil(this.totalLength / this.data.length);
        }
      }
      else if(action=='next'){
        
               if(this.totalLength == (this.start+this.data.length)) return;

        this.start = this.start+this.limit
        this.pageNo ++ ;
      }else{
        if(this.pageNo == 1) return
        this.start = this.start-this.limit
        this.pageNo -- ;
      }
      this.getData();
      this.tempPageNo = this.pageNo
    }
    refresh(){
      this.start = 0;
      this.limit = 50;
      this.filter = {};
      this.getData();
    }
    
    backClicked() {
      this._location.back();
    }
  }
  