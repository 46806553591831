import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import {MatDialog} from '@angular/material/dialog';
// import { BrandDetailModelComponent } from '../brand-detail-model/brand-detail-model.component';
import { DatabaseService } from 'src/app/_services/database.service';
import * as moment from 'moment';
import swal from 'sweetalert';
// import { RoleDetailModelComponent } from '../role-detail/role-detail-model.component';


@Component({
  selector: 'app-temp-users',
  templateUrl: './temp-users.component.html',
})
export class TempUsersListComponent implements OnInit {
  
  data:any=[];
  dataNotFound=false
  reqSent=false
  autoFocus?: boolean = true;
  
  constructor(
    private _location: Location,
    public dialog: MatDialog,
    public db:DatabaseService
    ) { }
    ngOnInit() {
      this.todayDate =  moment(new Date ()).format('YYYY-MM-DD')
      this.getData();
    }
    todayDate :any
    pageNo:any=1;
    tempPageNo =1;
    totalPages:any
    start:any=0;
    limit:any=50;
    totalLength:any;
    filter:any={};
    getData(){
      this.dataNotFound = false;
      this.reqSent = false;
      let reqData = {
        limit : this.limit,
        start : this.start,
        filter : this.filter
      }
      this.db.presentLoader();
      this.db.postReq(reqData,'temp_register_data/list').subscribe(resp=>{
        this.reqSent = true;
        if(resp['status'] == 'success'){
          this.data = resp['result'].data;
          if(!this.data.length) this.dataNotFound = true;
          this.totalLength = resp['result'].totalCount;
          this.totalPages = Math.ceil(this.totalLength / this.limit);
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
      this.db.errHandler(err);
        
      })
    }
    pagination(action){
      if(action == 'pageNo'){
        if(this.pageNo > 0 && this.pageNo <=this.totalPages){
          this.start = this.limit*(this.pageNo-1)
        }else{
          this.pageNo = Math.ceil(this.totalLength / this.data.length);
        }
      }
      else if(action=='next'){
               if(this.totalLength == (this.start+this.data.length)) return;

        this.start = this.start+this.limit
        this.pageNo ++ ;
      }else{
        if(this.pageNo == 1) return
        this.start = this.start-this.limit
        this.pageNo -- ;
      }
      this.getData();
      this.tempPageNo = this.pageNo
    }
    refresh(){
      this.start = 0;
      this.limit = 50;
      this.filter = {};
      this.getData();
    }
    delete(data){
      swal({
        title: "Are you sure?",
        text: data.status == 1  ? "You want to make this Inactive!" : "You want to make this Active!",
        icon: "warning",
        buttons: ["Cancel", "Confirm"],
        dangerMode: true,
      })
      .then((willDelete) => {        
        if (willDelete) {
          this.db.presentLoader();
         
          this.db.postReq(data,'').subscribe(resp=>{
            if(resp['status'] == 'success'){
              swal(data.status == 0 ? "Inactive!" : "Active", {
                icon: "success",
              });
            }else{
              if(resp['message']=='Invalid token'){
                this.db.sessionExpire();
                return;
              }
              this.db.presentAlert(resp['status'],resp['message'])
            }
          })
          
        } else {
          swal("Your data is safe!");
        }
      });
    }
    backClicked() {
      this._location.back();
    }
  
    resendOtp(data){
      swal({
        title: "Are you sure?",
        text: "You want to resend otp to "+data.mobile_no,
        icon: "warning",
        buttons: ["Cancel", "Confirm"],
        dangerMode: true,
      })
      .then((res) => {        
        if (res) {
          this.db.presentLoader();
         
          this.db.postReq({temp_user_id:data.encrypt_id},'temp_user/send_otp').subscribe(resp=>{
            if(resp['status'] == 'success'){
              swal(resp['message'], {
                icon: "success",
              });
              this.getData();
            }else{
              if(resp['message']=='Invalid token'){
                this.db.sessionExpire();
                return;
              }
              this.db.presentAlert(resp['status'],resp['message'])
            }
          })
          
        } else {
          // swal("Your data is safe!");
        }
      });
    }
    markRegistered(data){
      swal({
        title: "Are you sure?",
        text: "You want to mark "+ data.name+' ('+ data.mobile_no +') '+'registered',
        icon: "warning",
        buttons: ["Cancel", "Confirm"],
        dangerMode: true,
      })
      .then((res) => {        
        if (res) {
          this.db.presentLoader();
         
          this.db.postReq({temp_user_id:data.encrypt_id},'temp_user/mark_registered').subscribe(resp=>{
            if(resp['status'] == 'success'){
              swal(resp['message'], {
                icon: "success",
              });
              this.getData();
            }else{
              if(resp['message']=='Invalid token'){
                this.db.sessionExpire();
                return;
              }
              this.db.presentAlert(resp['status'],resp['message'])
            }
          })
          
        } else {
          swal("Your data is safe!");
        }
      });
    }
  }
  