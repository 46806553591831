import { Component, OnInit } from '@angular/core';
import { ReferralEditModelComponent } from '../referral-edit-model/referral-edit-model.component';
import { MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { DatabaseService } from 'src/app/_services/database.service';

@Component({
  selector: 'app-referral-list',
  templateUrl: './referral-list.component.html'
})
export class ReferralListComponent implements OnInit {
  data:any=[];
  
  // constructor(public dialog: MatDialog) { }
  
  // ngOnInit() {
  
  //   this.data = [
  //     {
  //       "content":"welcome Points",
  //       "point":"25",
  //       "url":"assets/img/welcome-gift.svg"
  //     },
  //     {
  //       "content":"Referral User Points",
  //       "point":"50",
  //       "url":"assets/img/referral.svg"
  //     },
  //     {
  //       "content":"Referral Owner Points",
  //       "point":"75",
  //       "url":"assets/img/redeem-gift.svg"
  //     },
  //     {
  //       "content":"Min Gift Redeem (%)",
  //       "point":"10",
  //       "url":"assets/img/gift.svg"
  //     },
  //     {
  //       "content":"Birthday Points",
  //       "point":"50",
  //       "url":"assets/img/birthday.svg"
  //     },
  //   ];
  // }
  
  constructor(public db:DatabaseService,public actRoute:ActivatedRoute,public dialog: MatDialog) { }
  
  ngOnInit() {
    this.getDetail();
  }
  reqSent:any=false
  getDetail(){
    this.reqSent = false
    this.db.presentLoader();
    
    this.db.postReq({},'master/referral_data_list').subscribe(resp=>{
      this.db.dismissLoader();
      this.reqSent = true
      if(resp['status'] == 'success'){
        this.data = resp['result'].data;
        // this.data = [
        //   {
        //     "content":"welcome Points",
        //     "point":resp['result'].data.welcome_point,
        //     "url":"assets/img/welcome-gift.svg"
        //   },
        //   {
        //     "content":"Referral User Points",
        //     "point":resp['result'].data.referral_user_point,
        //     "url":"assets/img/referral.svg"
        //   },
        //   {
        //     "content":"Referral Owner Points",
        //     "point":resp['result'].data.referral_owner_point,
        //     "url":"assets/img/redeem-gift.svg"
        //   },
        //   {
        //     "content":"Min Gift Redeem (%)",
        //     "point":resp['result'].data.min_gift_redeem,
        //     "url":"assets/img/gift.svg"
        //   },
        //   {
        //     "content":"Birthday Points",
        //     "point":resp['result'].data.birthday_points,
        //     "url":"assets/img/birthday.svg"
        //   },
        // ];
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
      this.db.dismissLoader();
    })
    
  }
  openDialog(data): void {
    const dialogRef = this.dialog.open(ReferralEditModelComponent, {
      width: '700px',
      autoFocus: false,
      data: data
    });
    
    dialogRef.afterClosed().subscribe(result => {
      this.getDetail();
    });
  }
  
}
