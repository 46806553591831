import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { DatabaseService } from 'src/app/_services/database.service';
import { StatusModelComponent } from '../../status-model/status-model.component';
import {Location} from '@angular/common';
import { AddBonusModelComponent } from '../../add-bonus-points/add-bonus.component';
import { CustomEventsService } from 'src/app/_services/custom-events.service';

@Component({
  selector: 'app-dealer-basic',
  templateUrl: './dealer-basic.component.html'
})
export class DealerBasicComponent implements OnInit {
  
  constructor(public customService:CustomEventsService,public dialog : MatDialog, public activatedroute:ActivatedRoute ,private _location: Location,public db:DatabaseService) { 
    customService.subscribe('refresh-lead-detail',resp=>{
      this.getDetail(this.activatedroute.snapshot.params.id)
    })
  }
  
  ngOnInit() {
    this.getDetail(this.activatedroute.snapshot.params.id)
  }

  editSalesUser:any=false
  usersAssigned:any=[];
  usersData:any=[]
  filteredUsersData:any=[]
  getUsers(){
    this.usersAssigned=[];
    let reqData = {
      limit : 30000,
      start : 0,
      filter : {}
    }
    this.db.presentLoader();
    this.db.postReq(reqData,'dis_network/sales_user').subscribe(resp=>{
      if(resp['status'] == 'success'){
        resp['result'].data.map(r=>{
          r.user_name = r.name
          r.user_id = r.id
        })
        this.usersData = resp['result'].data;
        this.filteredUsersData = this.usersData
        this.usersData.map(r=>{
          let i = this.data.salesUserDetail.findIndex(row=>row.user_id == r.id)
          if(i!=-1)this.usersAssigned.push(r)
        })
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }

  saveUsers(){
    this.db.presentLoader();
    this.db.postReq({customer_id:this.data.basicDetail.id,userIds:this.usersAssigned},'dis_network/assign_sales_user').subscribe(resp=>{
      if(resp['status'] == 'success'){
        this.editSalesUser=false
       this.getDetail(this.activatedroute.snapshot.params.id);
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }

  
  data:any={};
  getDetail(id){
    this.db.presentLoader();
    
    this.db.postReq({'customer_id':id},'dis_network/detail').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.data = resp['data']; 
        console.log(this.data);
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
    })
    
  }
  backClicked() {
    this._location.back();
  }
  
  
  openDialog(): void {
    const dialogRef = this.dialog.open(StatusModelComponent, {
      width: '360px',
      autoFocus: false,
      data: {}
    });
    
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  
  
}
