import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DatabaseService } from 'src/app/_services/database.service';

@Component({
  selector: 'app-role-detail-model',
  templateUrl: './detail-model.component.html'
})

export class ChatDetailModelComponent implements OnInit {
  gallery:any=[];
  // @Inject(MAT_DIALOG_DATA) public data: any
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public matdialog:MatDialog,public db:DatabaseService) {
    console.log(this.data);
    this.getData();
  }
  
  message:any
  ngOnInit() {
  }
  chatData:any;
  getData(){ 
    this.db.presentLoader();
    this.db.postReq({customer_id:this.data.customer_id},'feedback/detail').subscribe(resp=>{
      if(resp['status'] == 'success'){
        this.chatData = resp['result'].data;
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
    this.db.errHandler(err);
    })
  }

  attachment_filename:any
  changeListener($event): void {
    console.log($event);
    
    var file = $event.target.files[0];
    var reader = new FileReader();
    var image
    reader.onloadend = function () {
      image = reader.result;
    }
    setTimeout(() => {
      console.log(image);
      let reqData = {base64:image}
      this.db.presentLoader();
      this.db.postReq(reqData,'base64_to_file').subscribe(resp=>{
        this.db.dismissLoader();
        
        if(resp['status'] == 'success'){
          this.attachment_filename= resp['response'].fileName;
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        this.db.errHandler(err);
      })
    }, 100);
    
    reader.readAsDataURL(file); 
  }
  send(){
    if(!this.message && !this.attachment_filename) return

    let reqData =  {
      "customer_id": this.data.customer_id,
      "message": this.message,
      "attachment_filename": this.attachment_filename
    }
    this.db.presentLoader();
    this.db.postReq(reqData,'feedback/send').subscribe(resp=>{
      this.db.dismissLoader();
      
      if(resp['status'] == 'success'){
        // this.matdialog.closeAll();      
        this.getData();  
        this.attachment_filename = null;
        this.message = null;
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
    })
  }
}

