import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-page-menu',
  templateUrl: './page-menu.component.html'
})
export class PageMenuComponent implements OnInit {

  constructor(private router : Router,public activatedRoute:ActivatedRoute) {
    console.log('routername', this.router.url,this.activatedRoute.snapshot.params.id);
   }

  ngOnInit() {

  }

}
